import { GoogleOAuthProvider } from '@react-oauth/google';
import { Outlet } from '@remix-run/react'
import { useEnvironmentVariable } from '~/hooks/useEnvironmentVariable';

const LoginLayout = () => {
  const googleAuthClientId = useEnvironmentVariable( 'GOOGLE_AUTH_CLIENT_ID' );

  return (
    <GoogleOAuthProvider clientId={ googleAuthClientId }>
      <Outlet />
    </GoogleOAuthProvider>
  )
}

export default LoginLayout;